import { useEffect, useRef, useState } from 'react';
import { RequestState } from './request-state';

export interface RequestStateValues<T> {
  loading: boolean;
  completed: boolean;
  succeeded: boolean;
  result: T | undefined;
  failed: boolean;
  error: Error | undefined;
  noRequest: boolean;
  promise?: Promise<T>;
  cancel: () => void;
}

function getRequestStateValues<T>(state: RequestState<T> | undefined): RequestStateValues<T> {
  return {
    loading: state?.loading ?? false,
    completed: !!state && !state.loading,
    succeeded: !!state && !state.loading && !state.failed,
    failed: state?.failed ?? false,
    error: state?.error,
    result: state?.result,
    noRequest: !state,
    promise: state?.request,
    cancel: () => state?.cancel()
  };
}

export function useRequestState<T = void>(initial?: RequestState<T>):
  [state: RequestStateValues<T>, update: (state: RequestState<T>) => void, clear: () => void] {

  const request = useRef(initial);
  const [state, setState] = useState(() => getRequestStateValues<T>(initial));

  useEffect(() => {
    initial?.request.finally(() => {
      if (request.current !== initial) return;
      setState(getRequestStateValues(initial));
    });

    return () => request.current = undefined;
  }, []);

  const update = (x: RequestState<T>) => {
    request.current = x;
    setState(getRequestStateValues(x));

    x.request.finally(() => {
      if (request.current !== x) return;
      setState(getRequestStateValues(x));
    });
  };

  const clear = () => {
    request.current = undefined;
    setState(getRequestStateValues(undefined));
  };

  return [state, update, clear];
}